import { useRef, useState } from 'react'

import { FieldProps, useField } from 'formact'
import {
  Center,
  FormControl,
  Input,
  Pressable,
  Stack,
  WarningOutlineIcon,
} from 'native-base'

export default function NumberField(
  props: FieldProps & {
    label?: string
    centerLabel?: boolean
    disabled?: boolean
    placeholder?: string
  },
) {
  const field = useField<number>(props)
  const label = <FormControl.Label>{props.label}</FormControl.Label>
  const textField = useRef<{ focus: () => any }>()
  const [textNumber, setTextNumber] = useState('0')
  return (
    <Pressable onPress={() => textField.current?.focus()}>
      <FormControl isRequired={props.required}>
        <Stack>
          {props.centerLabel ? <Center>{label}</Center> : label}
          <Input
            ref={textField}
            textAlign={props.centerLabel ? 'center' : undefined}
            type="text"
            keyboardType="decimal-pad"
            value={textNumber}
            onChangeText={(text) => {
              const value = Number(text)
              if (!isNaN(value)) {
                field.update(value)
                setTextNumber(text)
              }
            }}
            selectTextOnFocus
            placeholder={props.placeholder}
            onBlur={() => field.onBlur()}
            isDisabled={props.disabled}
          />
          {field.showError ? (
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}>
              {field.errorMessage}
            </FormControl.ErrorMessage>
          ) : null}
        </Stack>
      </FormControl>
    </Pressable>
  )
}

// import { StyleSheet } from 'react-native'

// import { FieldProps, useField } from 'formact'
// import { TextInput } from 'react-native-paper'

// export default function NumberField(props: FieldProps & { label: string }) {
//   const field = useField<number>(props)

//   return (
//     <TextInput
//       keyboardType="numeric"
//       label={props.label}
//       value={field.fieldValue?.toString() || '0'}
//       onChangeText={(text) => {
//         const value = Number(text)
//         if (!isNaN(value)) {
//           field.update(value)
//         }
//       }}
//       style={styles.input}
//     />
//   )
// }

// const styles = StyleSheet.create({
//   input: {
//     marginBottom: 10,
//   },
// })
