import { useToast } from 'native-base'

import ToastAlert from '../components/ToastAlert'

type Notification = {
  description?: string
  title: string
  status: 'success' | 'error'
}

export default function useNotification() {
  const toast = useToast()

  return ({ description, title, status }: Notification) => {
    if (toast.isActive(title)) return

    toast.show({
      id: title,
      render: ({ id }) => {
        return (
          <ToastAlert
            id={id}
            description={description}
            variant="left-accent"
            title={title}
            onClose={toast.close}
            status={status}
          />
        )
      },
    })
  }
}
