import { Heading, HStack, VStack } from 'native-base'

import { calculateGPA, useData } from './DataProvider'

const GRADE_LEGEND = {
  A: 3.5,
  'B+': 3.25,
  B: 3,
  'C+': 2.5,
  C: 2,
  'D+': 1.5,
  D: 1,
  F: 0,
}

export default function GradeResult() {
  const data = useData()

  const gpa = calculateGPA(data.sections)
  const letter = Object.keys(GRADE_LEGEND).find((k) => gpa >= GRADE_LEGEND[k])
  return (
    <HStack
      alignItems="center"
      justifyContent="flex-end"
      px={8}
      space={4}
      pb={4}>
      <Heading flex={1}>Your grade is...</Heading>
      <VStack alignItems="center">
        <Heading size="4xl">{letter}</Heading>
        <Heading size="sm">({gpa.toFixed(2)})</Heading>
      </VStack>
    </HStack>
  )
}
