import { createContext, useCallback, useContext, useState } from 'react'

import { useDisclose } from 'native-base'

type Props = {
  children: any
}

const DEFAULT_SECTIONS = [
  {
    name: 'Assessments',
    weight: 80,
  },
  {
    name: 'Assignments',
    weight: 20,
  },
]

type ContextType = {
  sections: Section[]
  selectedSection?: Section
  modalOpen?: boolean
  openSectionModal: (section?: Section) => void
  closeSectionModal: () => void
  addSection: (section: Section) => void
  editSection: (section: Section) => void
  deleteSection: (section: Section) => void
}

const Context = createContext<ContextType>({
  sections: [],
  openSectionModal: () => {},
  closeSectionModal: () => {},
  addSection: () => {},
  editSection: () => {},
  deleteSection: () => {},
})

export function addUpGrades(grades?: Grades) {
  if (!grades) {
    return 0
  }

  let totalGrades = 0
  Object.keys(grades).forEach((key) => (totalGrades += grades[key] || 0))
  return totalGrades
}

const GRADE_LEVELS = {
  numberOfA: 4,
  numberOfB: 3,
  numberOfC: 2,
  numberOfD: 1,
  numberOfF: 0,
}

export function calculateGPA(sections: Section[]) {
  const sectionWithTotals = sections
    .map((s) => ({ ...s, totalGrades: addUpGrades(s.grades) }))
    .filter((s) => s.totalGrades)
  const totalWeight = sectionWithTotals

    .map((s) => s.weight)
    .reduce((acc, w) => w + acc, 0)

  return Number(
    sectionWithTotals
      .map((s) => {
        const { totalGrades, grades, weight } = s
        const totalPoints = Object.keys(GRADE_LEVELS)
          .map((k) => GRADE_LEVELS[k] * (grades?.[k] || 0))
          .reduce((acc, g) => acc + g, 0)

        const grade = totalPoints / totalGrades

        return (weight / totalWeight) * grade
      })
      .reduce((acc, value) => value + acc, 0)
      .toFixed(2),
  )
}

export default function DataProvider(props: Props) {
  const [sections, setSections] = useState<Section[]>(DEFAULT_SECTIONS)
  const [selectedSection, setSelectedSection] = useState<Section>(undefined)
  const { isOpen, onOpen, onClose } = useDisclose()

  const openSectionModal = useCallback((section?: Section) => {
    setSelectedSection(section)
    onOpen()
  }, [])

  const addSection = useCallback(
    (section: Section) => {
      const exists = sections.find((s) => s.name === section.name)

      if (exists) {
        throw new Error('Section name already in use.')
      }

      setSections((ss) => [...ss, section])
    },
    [sections],
  )
  const editSection = useCallback(
    (section: Section) => {
      const exists = sections.find((s) => s.name === section.name)

      if (!exists) {
        throw new Error('Section does not exist.')
      }
      setSections((ss) =>
        ss.map((s) => (section.name === s.name ? section : s)),
      )
    },
    [sections],
  )
  const deleteSection = useCallback(
    (section: Section) => {
      const exists = sections.find((s) => s.name === section.name)

      if (!exists) {
        throw new Error('Section does not exist.')
      }
      setSections((ss) => ss.filter((s) => section.name !== s.name))
    },
    [sections],
  )

  return (
    <>
      <Context.Provider
        value={{
          sections,
          selectedSection,
          openSectionModal,
          closeSectionModal: onClose,
          addSection,
          editSection,
          deleteSection,
          modalOpen: isOpen,
        }}>
        {props.children}
      </Context.Provider>
    </>
  )
}

export function useData() {
  return useContext(Context)
}
