import {
  StyleSheet,
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from 'react-native'

type Props = KeyboardAvoidingViewProps

export default function KeyboardAvoidingView(props: Props) {
  return (
    <RNKeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={styles.flex1}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
})
