import { MaterialIcons } from '@expo/vector-icons'
import Form from 'formact'
import {
  Box,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Text,
  useDisclose,
  VStack,
} from 'native-base'

import Card from './Card'
import { addUpGrades, useData } from './DataProvider'
import DeleteSectionButton from './DeleteSectionButton'
import NumberField from './NumberField'

export default function Section({ section }: { section: Section }) {
  const data = useData()
  const { isOpen, onOpen, onClose } = useDisclose(true)
  return (
    <Card key={section.name}>
      <Pressable onPress={isOpen ? onClose : onOpen}>
        <HStack bg="red.700" color="white" p={2} alignItems="center">
          <VStack space={1} flex={1}>
            <Heading color="white" size="md">
              {section.name}
            </Heading>
            <Text color="white">({section.weight}%)</Text>
          </VStack>
          <Icon
            size="40px"
            color="white"
            as={MaterialIcons}
            name={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          />
        </HStack>
      </Pressable>
      {isOpen ? (
        <VStack p={4}>
          <Form<Grades>
            initialValues={section.grades}
            onChange={(payload) => {
              if (payload.event.reason === 'changeValue') {
                data.editSection({ ...section, grades: payload.values })
              }
            }}>
            <HStack>
              <Box flex={1}>
                <NumberField label="A" centerLabel name="numberOfA" />
              </Box>
              <Box flex={1}>
                <NumberField label="B" centerLabel name="numberOfB" />
              </Box>
              <Box flex={1}>
                <NumberField label="C" centerLabel name="numberOfC" />
              </Box>
              <Box flex={1}>
                <NumberField label="D" centerLabel name="numberOfD" />
              </Box>
              <Box flex={1}>
                <NumberField label="F" centerLabel name="numberOfF" />
              </Box>
            </HStack>
          </Form>
          <Divider my={4} />

          <HStack alignItems="center" justifyContent="space-between">
            <IconButton
              _pressed={{
                backgroundColor: 'red.200',
              }}
              _hover={{
                backgroundColor: 'red.200',
              }}
              color="red.800"
              icon={
                <Icon
                  as={MaterialIcons}
                  name="edit"
                  size="30px"
                  color="red.800"
                />
              }
              onPress={() => data.openSectionModal(section)}
            />
            <Text>Total Graded: {addUpGrades(section.grades)}</Text>
            <DeleteSectionButton section={section} />
          </HStack>
        </VStack>
      ) : null}
    </Card>
  )
}
