import { Box, ScrollView, VStack } from 'native-base'

import { useData } from './DataProvider'
import GradeResult from './GradeResult'
import Header from './Header'
import KeyboardAvoidingView from './KeyboardAvoidingView'
import Section from './Section'

export default function Body() {
  const data = useData()
  return (
    <KeyboardAvoidingView>
      <VStack h="full" space={4}>
        <Box flex={1} w="full" height="full">
          <ScrollView>
            <Header />
            {data.sections.map((section) => {
              return <Section key={section.name} section={section} />
            })}
          </ScrollView>
        </Box>
        <GradeResult />
      </VStack>
    </KeyboardAvoidingView>
  )
}
