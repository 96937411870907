import { MaterialIcons } from '@expo/vector-icons'
import {
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from 'native-base'

import { useData } from './DataProvider'

export default function Header() {
  const { openSectionModal } = useData()
  return (
    <HStack pl={4} py={4} pr={1} space={3}>
      <VStack space={1} flex={1}>
        <Heading>Report Card</Heading>
        <Text fontSize="xs">
          Create your report card to calculate your grade
        </Text>
        <Divider maxW="50%" />
      </VStack>
      <IconButton
        _pressed={{
          backgroundColor: 'red.200',
        }}
        _hover={{
          backgroundColor: 'red.200',
        }}
        borderRadius={100}
        size="lg"
        variant="ghost"
        icon={<Icon as={MaterialIcons} name="add" size={10} color="red.800" />}
        color="red.800"
        onPress={() => openSectionModal()}
      />
    </HStack>
  )
}
