import { Box, Center, StatusBar } from 'native-base'

import Logo from './Logo'

export default function AppBar() {
  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="red.800" />
      <Box safeAreaTop py={3} bg="red.800">
        <Center>
          <Logo />
        </Center>
      </Box>
    </>
  )
}
