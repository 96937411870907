import { ComponentProps } from 'react'

import { Box } from 'native-base'

type Props = ComponentProps<typeof Box>

export default function Card(props: Props) {
  return (
    <Box
      m={4}
      bg="white"
      borderWidth={1}
      borderColor="gray.300"
      style={{
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
      }}
      {...props}
    />
  )
}
