import { Box } from 'native-base'

import AppBar from './components/AppBar'
import Body from './components/Body'
import DataProvider from './components/DataProvider'
import SectionModal from './components/SectionModal'

export default function Root() {
  return (
    <DataProvider>
      <Box safeAreaBottom w="full" h="full" bg="gray.100">
        <AppBar />

        <Body />
        <SectionModal />
      </Box>
    </DataProvider>
  )
}
