import { Circle, G, Path, Svg } from 'react-native-svg'

export default function Logo() {
  return (
    <Svg width="272" height="46" viewBox="0 0 272 46" fill="none">
      <G>
        <Path
          d="M19.0833 27.9684V21.0425H11.7984V22.2738H17.8007V27.4297C17.4758 27.7033 17.0996 27.9684 16.6721 28.2249C16.2446 28.4643 15.7829 28.678 15.2869 28.8662C14.8081 29.0543 14.2951 29.2082 13.7479 29.3279C13.2006 29.4305 12.6449 29.4818 12.0805 29.4818C10.8835 29.4818 9.80612 29.2766 8.84847 28.8662C7.90793 28.4557 7.10419 27.8914 6.43726 27.1732C5.77033 26.4549 5.25731 25.5999 4.89819 24.6081C4.55617 23.6162 4.38516 22.5389 4.38516 21.376V21.3247C4.38516 20.2474 4.56472 19.2213 4.92384 18.2466C5.28296 17.2718 5.78743 16.4168 6.43726 15.6814C7.10419 14.9461 7.89083 14.3647 8.79717 13.9372C9.70352 13.5096 10.7039 13.2959 11.7984 13.2959C13.1493 13.2959 14.2694 13.5011 15.1587 13.9115C16.065 14.3219 16.8688 14.8349 17.5699 15.4506L18.442 14.4502C17.9974 14.074 17.5357 13.7405 17.0569 13.4498C16.5951 13.1591 16.0992 12.9111 15.5691 12.7059C15.0561 12.5007 14.4917 12.3468 13.8761 12.2442C13.2776 12.1416 12.6107 12.0903 11.8753 12.0903C10.5415 12.0903 9.3273 12.3468 8.23285 12.8598C7.13839 13.3728 6.19785 14.0569 5.41121 14.9119C4.64168 15.7669 4.04315 16.7588 3.61563 17.8874C3.20521 18.999 3 20.1618 3 21.376V21.4273C3 22.6928 3.20521 23.8898 3.61563 25.0185C4.02605 26.13 4.61603 27.1048 5.38556 27.9427C6.1551 28.7807 7.09564 29.4476 8.20719 29.9435C9.33585 30.4223 10.6099 30.6617 12.0292 30.6617C13.4999 30.6617 14.8338 30.3967 16.0308 29.8666C17.245 29.3193 18.2625 28.6866 19.0833 27.9684Z"
          fill="white"
        />
        <Path
          d="M38.3726 18.4518V17.0666C37.7228 17.0495 37.0986 17.135 36.5001 17.3231C35.9186 17.4941 35.38 17.7592 34.884 18.1183C34.3881 18.4603 33.9435 18.8793 33.5502 19.3752C33.1569 19.8711 32.8319 20.4098 32.5754 20.9912V17.2718H31.3185V30.3539H32.5754V24.9928C32.5754 23.9326 32.7293 23.0006 33.0372 22.1968C33.345 21.376 33.7554 20.692 34.2684 20.1447C34.7985 19.5804 35.4056 19.1615 36.0897 18.8878C36.7737 18.5971 37.4919 18.4518 38.2444 18.4518H38.3726Z"
          fill="white"
        />
        <Path
          d="M66.0296 30.3539L57.6673 12.2698H56.3848L48.0225 30.3539H49.4076L51.6906 25.3263H62.2845L64.5675 30.3539H66.0296ZM61.7202 24.1207H52.2549L57.0004 13.7319L61.7202 24.1207Z"
          fill="white"
        />
        <Path
          d="M74.7264 20.7091H69.1601V21.9403H74.7264V27.4553H76.0089V21.9403H81.5752V20.7091H76.0089V15.1941H74.7264V20.7091Z"
          fill="white"
        />
        <Path
          d="M105.802 30.3539V11.6285H104.545V20.0934C104.271 19.683 103.955 19.2897 103.596 18.9135C103.254 18.5373 102.86 18.2038 102.416 17.9131C101.988 17.6224 101.501 17.3915 100.954 17.2205C100.406 17.0495 99.8078 16.964 99.158 16.964C98.3713 16.964 97.5932 17.1265 96.8237 17.4514C96.0713 17.7592 95.3958 18.2124 94.7972 18.8109C94.1987 19.3923 93.7113 20.1105 93.3351 20.9656C92.976 21.8035 92.7964 22.7526 92.7964 23.8129V23.8642C92.7964 24.9244 92.976 25.8821 93.3351 26.7371C93.7113 27.575 94.1987 28.2847 94.7972 28.8662C95.3958 29.4476 96.0713 29.8922 96.8237 30.2C97.5932 30.5078 98.3713 30.6617 99.158 30.6617C99.8078 30.6617 100.398 30.5677 100.928 30.3796C101.475 30.2086 101.962 29.9777 102.39 29.687C102.835 29.3963 103.228 29.0543 103.57 28.6609C103.929 28.2676 104.254 27.8572 104.545 27.4297V30.3539H105.802ZM104.622 23.8385C104.622 24.6765 104.468 25.4374 104.16 26.1215C103.869 26.8055 103.476 27.3955 102.98 27.8914C102.484 28.3873 101.911 28.7721 101.261 29.0457C100.629 29.3193 99.9702 29.4561 99.2862 29.4561C98.6022 29.4561 97.9438 29.3279 97.3111 29.0714C96.6954 28.7978 96.1482 28.4215 95.6694 27.9427C95.2077 27.4468 94.84 26.8483 94.5664 26.1471C94.2928 25.446 94.156 24.6679 94.156 23.8129V23.7616C94.156 22.8894 94.2842 22.1113 94.5407 21.4273C94.8143 20.7262 95.182 20.1362 95.6437 19.6574C96.1055 19.1786 96.6441 18.8109 97.2598 18.5544C97.8925 18.2979 98.568 18.1696 99.2862 18.1696C99.9702 18.1696 100.629 18.3064 101.261 18.58C101.911 18.8536 102.484 19.2384 102.98 19.7343C103.476 20.2132 103.869 20.8031 104.16 21.5043C104.468 22.1883 104.622 22.9493 104.622 23.7872V23.8385Z"
          fill="white"
        />
        <Path
          d="M118.703 24.3259H129.554C129.571 24.2233 129.579 24.1463 129.579 24.095C129.579 24.0266 129.579 23.9497 129.579 23.8642V23.8129C129.579 22.8381 129.434 21.9403 129.143 21.1195C128.87 20.2816 128.468 19.5548 127.938 18.9391C127.425 18.3235 126.8 17.8447 126.065 17.5027C125.33 17.1436 124.5 16.964 123.577 16.964C122.688 16.964 121.867 17.1436 121.114 17.5027C120.362 17.8618 119.704 18.3492 119.139 18.9648C118.592 19.5804 118.156 20.3072 117.831 21.1451C117.523 21.966 117.369 22.8467 117.369 23.7872V23.8385C117.369 24.8646 117.54 25.7966 117.882 26.6345C118.224 27.4724 118.686 28.1907 119.267 28.7892C119.866 29.3877 120.55 29.8495 121.32 30.1744C122.106 30.4993 122.927 30.6617 123.782 30.6617C125.03 30.6617 126.074 30.4309 126.912 29.9692C127.767 29.5074 128.528 28.9175 129.195 28.1992L128.322 27.4297C127.775 28.0111 127.142 28.4985 126.424 28.8918C125.723 29.2851 124.859 29.4818 123.833 29.4818C123.184 29.4818 122.559 29.3706 121.961 29.1483C121.379 28.9089 120.858 28.5669 120.396 28.1223C119.934 27.6777 119.55 27.139 119.242 26.5062C118.934 25.8735 118.754 25.1467 118.703 24.3259ZM118.703 23.1972C118.754 22.479 118.917 21.8121 119.191 21.1964C119.464 20.5637 119.806 20.025 120.217 19.5804C120.644 19.1187 121.14 18.7596 121.704 18.5031C122.269 18.2466 122.876 18.1183 123.526 18.1183C124.278 18.1183 124.936 18.2637 125.501 18.5544C126.065 18.828 126.535 19.2042 126.912 19.683C127.305 20.1447 127.604 20.6834 127.809 21.2991C128.032 21.8976 128.168 22.5303 128.22 23.1972H118.703Z"
          fill="white"
        />
        <Path
          d="M161.556 13.6293V12.3981H148.91V30.3539H150.244V22.2225H160.402V20.9912H150.244V13.6293H161.556Z"
          fill="white"
        />
        <Path
          d="M174.742 13.8602V12.1416H173.177V13.8602H174.742ZM174.588 30.3539V17.2718H173.331V30.3539H174.588Z"
          fill="white"
        />
        <Path
          d="M198.439 30.3539V22.3507C198.439 21.5641 198.32 20.8459 198.08 20.196C197.858 19.5291 197.516 18.9562 197.054 18.4774C196.61 17.9986 196.062 17.6309 195.412 17.3744C194.763 17.1008 194.027 16.964 193.206 16.964C192.009 16.964 191.035 17.2291 190.282 17.7592C189.53 18.2893 188.931 18.9391 188.487 19.7087V17.2718H187.23V30.3539H188.487V22.7868C188.487 22.1028 188.598 21.4786 188.82 20.9143C189.06 20.3329 189.384 19.8369 189.795 19.4265C190.205 19.0161 190.684 18.6997 191.231 18.4774C191.779 18.238 192.377 18.1183 193.027 18.1183C194.361 18.1183 195.387 18.5287 196.105 19.3496C196.823 20.1533 197.182 21.2306 197.182 22.5816V30.3539H198.439Z"
          fill="white"
        />
        <Path
          d="M222.81 30.3539V11.6285H221.553V20.0934C221.28 19.683 220.963 19.2897 220.604 18.9135C220.262 18.5373 219.869 18.2038 219.424 17.9131C218.997 17.6224 218.509 17.3915 217.962 17.2205C217.415 17.0495 216.816 16.964 216.167 16.964C215.38 16.964 214.602 17.1265 213.832 17.4514C213.08 17.7592 212.404 18.2124 211.806 18.8109C211.207 19.3923 210.72 20.1105 210.344 20.9656C209.985 21.8035 209.805 22.7526 209.805 23.8129V23.8642C209.805 24.9244 209.985 25.8821 210.344 26.7371C210.72 27.575 211.207 28.2847 211.806 28.8662C212.404 29.4476 213.08 29.8922 213.832 30.2C214.602 30.5078 215.38 30.6617 216.167 30.6617C216.816 30.6617 217.406 30.5677 217.936 30.3796C218.484 30.2086 218.971 29.9777 219.399 29.687C219.843 29.3963 220.237 29.0543 220.579 28.6609C220.938 28.2676 221.263 27.8572 221.553 27.4297V30.3539H222.81ZM221.63 23.8385C221.63 24.6765 221.476 25.4374 221.168 26.1215C220.878 26.8055 220.484 27.3955 219.989 27.8914C219.493 28.3873 218.92 28.7721 218.27 29.0457C217.637 29.3193 216.979 29.4561 216.295 29.4561C215.611 29.4561 214.952 29.3279 214.32 29.0714C213.704 28.7978 213.157 28.4215 212.678 27.9427C212.216 27.4468 211.849 26.8483 211.575 26.1471C211.301 25.446 211.165 24.6679 211.165 23.8129V23.7616C211.165 22.8894 211.293 22.1113 211.549 21.4273C211.823 20.7262 212.191 20.1362 212.652 19.6574C213.114 19.1786 213.653 18.8109 214.268 18.5544C214.901 18.2979 215.577 18.1696 216.295 18.1696C216.979 18.1696 217.637 18.3064 218.27 18.58C218.92 18.8536 219.493 19.2384 219.989 19.7343C220.484 20.2132 220.878 20.8031 221.168 21.5043C221.476 22.1883 221.63 22.9493 221.63 23.7872V23.8385Z"
          fill="white"
        />
        <Path
          d="M235.712 24.3259H246.562C246.579 24.2233 246.588 24.1463 246.588 24.095C246.588 24.0266 246.588 23.9497 246.588 23.8642V23.8129C246.588 22.8381 246.442 21.9403 246.152 21.1195C245.878 20.2816 245.476 19.5548 244.946 18.9391C244.433 18.3235 243.809 17.8447 243.074 17.5027C242.338 17.1436 241.509 16.964 240.585 16.964C239.696 16.964 238.875 17.1436 238.123 17.5027C237.37 17.8618 236.712 18.3492 236.148 18.9648C235.601 19.5804 235.165 20.3072 234.84 21.1451C234.532 21.966 234.378 22.8467 234.378 23.7872V23.8385C234.378 24.8646 234.549 25.7966 234.891 26.6345C235.233 27.4724 235.695 28.1907 236.276 28.7892C236.875 29.3877 237.559 29.8495 238.328 30.1744C239.115 30.4993 239.936 30.6617 240.791 30.6617C242.039 30.6617 243.082 30.4309 243.92 29.9692C244.775 29.5074 245.536 28.9175 246.203 28.1992L245.331 27.4297C244.784 28.0111 244.151 28.4985 243.433 28.8918C242.732 29.2851 241.868 29.4818 240.842 29.4818C240.192 29.4818 239.568 29.3706 238.969 29.1483C238.388 28.9089 237.866 28.5669 237.405 28.1223C236.943 27.6777 236.558 27.139 236.25 26.5062C235.943 25.8735 235.763 25.1467 235.712 24.3259ZM235.712 23.1972C235.763 22.479 235.925 21.8121 236.199 21.1964C236.473 20.5637 236.815 20.025 237.225 19.5804C237.653 19.1187 238.149 18.7596 238.713 18.5031C239.277 18.2466 239.884 18.1183 240.534 18.1183C241.287 18.1183 241.945 18.2637 242.509 18.5544C243.074 18.828 243.544 19.2042 243.92 19.683C244.313 20.1447 244.613 20.6834 244.818 21.2991C245.04 21.8976 245.177 22.5303 245.228 23.1972H235.712Z"
          fill="white"
        />
        <Path
          d="M265 18.4518V17.0666C264.35 17.0495 263.726 17.135 263.127 17.3231C262.546 17.4941 262.007 17.7592 261.511 18.1183C261.016 18.4603 260.571 18.8793 260.178 19.3752C259.784 19.8711 259.459 20.4098 259.203 20.9912V17.2718H257.946V30.3539H259.203V24.9928C259.203 23.9326 259.357 23.0006 259.665 22.1968C259.972 21.376 260.383 20.692 260.896 20.1447C261.426 19.5804 262.033 19.1615 262.717 18.8878C263.401 18.5971 264.119 18.4518 264.872 18.4518H265Z"
          fill="white"
        />
      </G>
      <Circle
        cx="64.0072"
        cy="22.6586"
        r="21.6586"
        stroke="white"
        stroke-width="2"
      />
    </Svg>
  )
}
