import { useEffect } from 'react'
import { Keyboard } from 'react-native'

import Form from 'formact'
import { Modal } from 'native-base'

import { useData } from './DataProvider'
import NumberField from './NumberField'
import SubmitButton from './SubmitButton'
import TextField from './TextField'
import useNotification from '../hooks/useNotification'

export default function SectionModal() {
  const data = useData()
  const notify = useNotification()

  useEffect(() => {
    Keyboard.dismiss()
  }, [data.modalOpen])

  if (!data.modalOpen) {
    return null
  }

  return (
    <Modal isOpen onClose={data.closeSectionModal}>
      <Form<Section>
        initialValues={data.selectedSection}
        onSubmit={(payload) => {
          try {
            if (payload.valid) {
              let title = ''

              if (data.selectedSection) {
                data.editSection({
                  ...data.selectedSection,
                  ...payload.values,
                })
                title = 'Section edited'
              } else {
                data.addSection(payload.values)
                title = 'Section created'
              }
              notify({
                title,
                status: 'success',
              })
              payload.onFinish(true)

              data.closeSectionModal()
            }
          } catch (e: any) {
            payload.onFinish()
            notify({
              title: 'Something went wrong',
              description: e.message,
              status: 'error',
            })
          }
        }}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>
            {data.selectedSection
              ? `Edit ${data.selectedSection.name}`
              : 'Add New Section'}
          </Modal.Header>
          <Modal.Body>
            <TextField
              name="name"
              label="Name"
              required
              disabled={!!data.selectedSection}
              placeholder="Example: Assignments"
            />
            <NumberField name="weight" label="Weight" required />
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton w="full">Submit</SubmitButton>
          </Modal.Footer>
        </Modal.Content>
      </Form>
    </Modal>
  )
}
