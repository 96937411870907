import { NativeBaseProvider } from 'native-base'

import Root from './src/Root'

export default function App() {
  return (
    <NativeBaseProvider>
      <Root />
    </NativeBaseProvider>
  )
}
