import { MaterialIcons } from '@expo/vector-icons'
import {
  Icon,
  IconButton,
  Modal,
  useDisclose,
  Text,
  Button,
  HStack,
} from 'native-base'

import { useData } from './DataProvider'

export default function DeleteSectionButton({ section }: { section: Section }) {
  const data = useData()
  const { isOpen, onOpen, onClose } = useDisclose()
  return (
    <>
      <IconButton
        _hover={{
          backgroundColor: 'red.200',
        }}
        _pressed={{
          backgroundColor: 'red.200',
        }}
        onPress={onOpen}
        color="red.800"
        icon={
          <Icon as={MaterialIcons} name="delete" size="30px" color="red.800" />
        }
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Comfirm Deletion</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to delete this section?</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={4} w="full" justifyContent="space-between">
              <Button
                variant="outline"
                borderColor="red.700"
                onPress={onClose}
                colorScheme="red">
                Cancel
              </Button>
              <Button
                onPress={() => data.deleteSection(section)}
                _hover={{
                  backgroundColor: 'red.200',
                }}
                _pressed={{
                  backgroundColor: 'red.700',
                }}
                backgroundColor="red.800">
                Delete
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}
