import { ComponentProps } from 'react'

import { useForm } from 'formact'
import { Button } from 'native-base'

type Props = ComponentProps<typeof Button>

export default function SubmitButton(props: Props) {
  const form = useForm()
  return (
    <Button
      _pressed={{
        backgroundColor: 'red.700',
      }}
      backgroundColor="red.800"
      onPress={() => form.submit()}
      {...props}
    />
  )
}
