import { FieldProps, useField } from 'formact'
import { FormControl, Input, Stack, WarningOutlineIcon } from 'native-base'

export default function TextField(
  props: FieldProps & {
    label: string
    disabled?: boolean
    placeholder?: string
  },
) {
  const field = useField<string>(props)

  return (
    <FormControl isRequired={props.required}>
      <Stack>
        <FormControl.Label>{props.label}</FormControl.Label>
        <Input
          type="text"
          value={field.fieldValue}
          placeholder={props.placeholder}
          onBlur={() => field.onBlur()}
          isDisabled={props.disabled}
          onChangeText={(text) => field.update(text)}
        />
        {field.showError ? (
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            {field.errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </Stack>
    </FormControl>
  )
}
